
import { FaHome, FaTasks, FaPoo, FaUserAstronaut } from 'react-icons/fa';
import { MdOutlineContactMail } from 'react-icons/md';


const NavBar = () => {
  return (
    <div className="fixed top-0 left-0 w-screen h-12 flex flex-row
                  bg-white dark:bg-gray-900 shadow-lg">
        <SideBarIcon icon={<FaHome size="28" />} text={'Home'} />
        <SideBarIcon icon={<FaUserAstronaut size="28" />} text={'About Me'} />
        <SideBarIcon icon={<FaTasks size="32" />} text={'Recent Works'} />
        <SideBarIcon icon={<MdOutlineContactMail size="20" />} text={'Contact Me'}/>
        <Divider />
       
    </div>
  );
};

const SideBarIcon = ({ icon, text = '1' }) => (
  <div className="sidebar-icon group">
    {icon}
    <span class="sidebar-tooltip group-hover:scale-100">
      {text}
    </span>
  </div>
);


const Divider = () => <hr className="sidebar-hr" />;

export default NavBar;