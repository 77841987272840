import logo from './logo.svg';
import './App.css';
import NavBar from './NavBar';

function App() {
  return (
   <div className="flex">
      <NavBar />
     
    </div>
   
  );
}

export default App;
